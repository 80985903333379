module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Castellan","short_name":"Castellan","start_url":"/","background_color":"#2595ff","theme_color":"#2595ff","display":"minimal-ui","icon":"src/images/castellan-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53d75171a5bfe1421f3d5b6f25f8778d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://castellan.co"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-101615325-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"3441109","productionOnly":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"2238861623011709"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"qw4apiaz","include_in_development":false,"delay_timeout":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
